import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-scroll';
import { links } from '../utils/links';
import styled from 'styled-components';

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <NavBarContainer name="nav">
        <Container maxWidth='xl' style={{ position: 'absolute' }}>
          <Toolbar
            disableGutters
            sx={{
              width: '100%',
              mx: 'auto',
            }}
          >
            <Button
              onClick={handleCloseNavMenu}
              disableRipple={true}
              style={{ backgroundColor: 'transparent' }}
              alt='main-logo'
            >
              <Link spy={true} smooth={true} duration={500} to='nav'>
                <LogoImg src='/logo.png' alt='logo'></LogoImg>
              </Link>
            </Button>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
                alt='menu-icon-nav'
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                disableScrollLock={false}
              >
                {links.map(
                  (link, idx) =>
                     (
                      <MenuItem
                        key={idx}
                        onClick={handleCloseNavMenu}
                        onBlur={handleCloseNavMenu}
                      >
                        <Typography>
                          <Link
                            spy={true}
                            smooth={true}
                            duration={500}
                            to={link.to}
                          >
                            {link.name}
                          </Link>
                        </Typography>
                      </MenuItem>
                    )
                )}
              </Menu>
            </Box>

            <NavLinksContainer className='nav-links-container'>
              {links.map((link, idx) => (
                <Button
                  key={idx}
                  onClick={handleCloseNavMenu}
                  alt='link-buttons'
                >
                  <StyledLink
                    spy={true}
                    smooth={true}
                    duration={500}
                    to={link.to}
                  >
                    {link.name}
                  </StyledLink>
                </Button>
              ))}
            </NavLinksContainer>
          </Toolbar>
        </Container>
      </NavBarContainer>
    </>
  );
};
export default Navbar;

const NavBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;
  background-color: #b5d7a8;
  padding-bottom: 1rem


  @media (min-width: 375px) and (max-width: 768px) {
    height: 200px;
    background-color: #b5d7a8;
  }
`;

const LogoImg = styled.img`
  position: relative;
  width: 300px;
  margin-left: 1.5rem;
  top: 30px;
  loading: eager;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    margin-left: 12rem;
  }
`;
const NavLinksContainer = styled(Box)`
  width: 70%;
  display: flex;
  color: black !important;
  justify-content: space-between;
  margin-top: 5rem;
  margin-left: 2rem;


  @media (min-width: 320px) and (max-width: 375px) {
    display: none;
  }


  @media (min-width: 375px) and (max-width: 768px) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  color: #7a49a5;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
`;
