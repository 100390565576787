import React from 'react';
import Form from './Form';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { StyledDivider } from '../shared/sharedComponents';
import clientJson from '../utils/client.json';

const ContactMe = () => {
  const { email, phoneNumber } = clientJson;

  return (
    <ContactMeContainer name='contactUs'>
      <StyledDivider>Contact Me</StyledDivider>

      <ConsultContactMeContainer>
        <Form />

        <NumberEmailContainer>
          <h3>Contact Information</h3>

          <a
            className='facebook-icon'
            href='https://www.facebook.com/profile.php?id=61557043773549&mibextid=ZbWKwL9'
          >
            {' '}
            <FacebookImg src='/facebook.png' alt='facebook' />
            <p>Facebook</p>
          </a>

          <div className='info'>
            <img src='/phone.png' height={30} width={30} alt='phone-icon' />
            <p style={{ color: '#485643' }}>{phoneNumber}</p>
          </div>
          <div className='info email'>
            <img src='/email.png' height={30} width={30} alt='mail-icon' />
            <a
              href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
              style={{ color: '#485643' }}
              className='link'
            >
              {/* {process.env.REACT_APP_CONTACT_EMAIL} */}
              {clientJson.email}
            </a>
          </div>

          <AddressContainer>
            <img src='/location.png' height={30} width={30} alt='location' />

            <div className='address'>
              <p>998 E Ridge Dr. Suite F</p>
              <p>Lebanon, OH 45036</p>
            </div>
          </AddressContainer>
          <MapContainer>
            <img src='/map-locator.png' alt='map' height={30} width={30} />
            <a
              href='https://www.google.com/maps/place/998+E+Ridge+Dr+Suite+F,+Lebanon,+OH+45036/@39.4468352,-84.1837028,16z/data=!3m1!4b1!4m6!3m5!1s0x8840f38afa70564d:0x585f0e4eac92128!8m2!3d39.4468352!4d-84.1837028!16s%2Fg%2F11lmg2m1p7?entry=ttu&g_ep=EgoyMDI0MDkyNS4wIKXMDSoASAFQAw%3D%3D'
              target='_blank'
              rel='noreferrer'
              className='link'
            >
              <p>Click here to see our location on a map!</p>
            </a>
          </MapContainer>
        </NumberEmailContainer>
      </ConsultContactMeContainer>
    </ContactMeContainer>
  );
};

export default ContactMe;

const ConsultContactMeContainer = styled.div`
  display: flex;
  gap: 2rem;

  @media (min-width: 320px) and (max-width: 375px) {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 375px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const ContactMeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  padding: 2rem;

  h1 {
    font-weight: 100;
    text-align: center;
    @media (min-width: 375px) and (max-width: 768px) {
      padding: 2rem;
      display: flex;
      flex-direction: column;
    }
  }
`;

const NumberEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 22px;
  height: 500px;
  padding: 2rem;
  margin: auto;

  h3 {
    font-weight: 300;
    @media (min-width: 320px) and (max-width: 375px) {
      text-align: center;
      font-size: 21px;
      font-weight: 400;
  }
  }

  .facebook-icon {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #485643;
  }

  .info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
    color: #485643;
  }

  .info.email {
    margin-top: 0.5rem;
    @media (min-width: 375px) and (max-width: 768px) {
      font-size: 15px;
    }
  }
`;

const FacebookImg = styled.img`
  height: 30px;
  width: 30px;
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
  gap: 1rem;
  color: #485643;

  .address {
    line-height: 4pt;
    text-align: start;
    font-size: 22px;

    @media (min-width: 320px) and (max-width: 375px) {
      font-size: 15px;
    }

    @media (min-width: 375px) and (max-width: 768px) {
      font-size: 15px;
    }
  }
`;

const MapContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  a {
    text-decoration: none;
  }

  .link:hover {
    cursor: pointer;
    color: #b08bbb;
  }
`;
