import React from 'react';
import styled from 'styled-components';

const Location = () => {
  return (
    <LocationContainer name='location'>
      <LocationText>
        <LocationHeader>
          <b>
            Do you want to know why your child struggles with reading and how to
            help them?
          </b>
        </LocationHeader>
        <LocationHeader>
          <b>Let’s find some answers together.</b>
        </LocationHeader>
        <p>
          All learners deserve to feel respected and valued for their talents.{' '}
        </p>
        <p>People with dyslexia deserve access to an accurate diagnosis.</p>
        <p>
          Families and educators deserve an assessment that identifies strengths
          as well as weaknesses, and can be used as a blueprint for effective
          learning.
        </p>
        <p>
          Families living with dyslexia deserve to feel some control over
          instructional decisions regarding reading interventions.
        </p>

        <Line />

        <p>
          <p
            style={{ color: '#7a49a5', fontWeight: '400', textAlign: 'center' }}
          >
            OWL Diagnostics
          </p>{' '}
          <p>
            is an independently owned speech and language clinic that
            specializes in reading and written language.
          </p>
        </p>

        <p>
          A comprehensive evaluation is offered in a comfortable and accessible
          environment where your input is valued and your concerns are heard.
        </p>
      </LocationText>

      <ImageContainer>
        <img src='/office.png' alt='office1' />
        <img src='/office2.png' alt='office2' />
      </ImageContainer>
    </LocationContainer>
  );
};

export default Location;

const LocationContainer = styled.div`
  color: #485643;
  display: flex;
  flex-direction: column;
`;

const Line = styled.hr`
  width: 50%;
`;

const LocationText = styled.p`
  text-align: center;
  padding: 2rem;

  p {
    font-size: 20px;
  }

  @media (min-width: 375px) and (max-width: 768px) {
    font-size: 20px;
    line-height: 2rem;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  img {
    width: 700px;
    height: 450px;
    loading: eager;

    @media (min-width: 320px) and (max-width: 375px) {
      width: 280px;
      height: 180px;
  }

    @media (min-width: 375px) and (max-width: 768px) {
      width: 300px;
      height: 200px;
    }
  }
  @media (min-width: 320px) and (max-width: 375px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: 375px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const LocationHeader = styled.p`
  font-size: 25px;
  font-style: italic;
`;
