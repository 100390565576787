export const links = [
  {
    to: 'aboutMe',
    name: 'About Me',
  },
  {
    to: 'services',
    name: 'Services',
  },
  {
    to: 'resources',
    name: 'Resources',
  },
  {
    to: 'contactUs',
    name: 'Contact',
  },
];
