import React from 'react';
import styled from 'styled-components';
import clientJson from '../utils/client.json';
import { StyledDivider } from '../shared/sharedComponents';

const AboutMe = () => {
  const { clientName, credentials, clientTitle } = clientJson;

  return (
    <AboutMeContainer name='aboutMe'>
      <StyledDivider style={{ marginTop: '2rem' }}>About Me</StyledDivider>
      <AboutMeSection>
        <ProfileImg src='/profilePhoto.png' alt='profile-pic'></ProfileImg>
        <AboutMeParagraphContainer>
          <div className='clinician-header-container'>
            <p className='clinician-name'>
              {clientName} {credentials}
            </p>
            <p className='clinician-title'>
              <i>{clientTitle}</i>
            </p>
          </div>

          <div className='paragraph'>
            <p>
              Denise Lacy, MS CCC-SLP; C-SLDI is a licensed and nationally
              certified Speech-Language Pathologist and certified Structured
              Literacy and Dyslexia Interventionist. She graduated from the
              University of Louisville with a masters degree in Communication
              Disorders and received an additional masters degree in Reading
              Science from Mount St. Joseph University in 2024. She has over 25
              years of experience serving children with speech and language
              disorders in a variety of settings, mostly public schools. Her
              interest in the connections between speech, language and literacy
              spans her entire career. She is specializing in dyslexia and
              written language evaluations conducted in an inviting and
              comfortable clinic setting.
            </p>
          </div>
        </AboutMeParagraphContainer>
      </AboutMeSection>
    </AboutMeContainer>
  );
};

export default AboutMe;

const AboutMeContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 32px;
  font-weight: bold;
  background-color: white;


  @media (min-width: 375px) and (max-width: 768px) {
    margin-top: 3rem;
  }
`;

const AboutMeSection = styled.div`
  display: flex;
  gap: 1rem;
  padding: 2rem;
  align-items: center;


  @media (min-width: 320px) and (max-width: 375px) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    font-size: 30px !important;
  }

  @media (min-width: 375px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`;

const ProfileImg = styled.img`
  height: 100%;
  width: 36%;
  border-radius: 5px;
  margin-top: -0.5rem;
  padding: 1.5rem;

  @media (min-width: 320px) and (max-width: 375px) {
    width: 90%;
    object-fit: cover;
    height: auto;
  }

  @media (min-width: 375px) and (max-width: 768px) {
    width: 90%;
    object-fit: cover;
    height: auto;
  }
`;

const AboutMeParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;

  .clinician-header-container {
    font-size: 30px;
    text-align: start;

    .clinician-name {
      margin: auto;
      margin-left: 1.2rem;
      color: #485643;
    }

    .clinician-title {
      font-size: 16px;
      margin-left: 1rem;
      color: #485643;
    }
  }
  .paragraph {
    padding: 1.2rem;
    font-size: 24px;
    line-height: 22pt;
    font-weight: 350;
    text-align: start;
    margin-top: -2rem;
    @media (min-width: 320px) and (max-width: 375px) {
      font-size: 20px;
    }
    @media (min-width: 375px) and (max-width: 768px) {
      font-size: 20px;
    }
  }
`;
