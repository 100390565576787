import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import styled from 'styled-components';
import Box from '../shared/Box';

const ServiceTable = () => {
  const titles = [
    'Dyslexia Testing',
    'Articulation Testing',
    'Language Testing',
  ];

  const services = [
    'Comprehensive evaluation of written language',
    'Analysis of articulation errors and speech sound production patterns',
    'Assessment of receptive and expressive language skills',
  ];

  const payOptions = [
    'Private Pay',
    'Payment through school systems',
    'HSA/FSA',
  ];

  return (
    <>
      <TableParent>
        <TableContainer>
          <TableTitle background='#B5D5C5' fontSize='22px' color='#485643'>
            What We Do
          </TableTitle>
          <WhatWeDoContainer>
            {titles.map((title, idx) => {
              return (
                <>
                  <Box key={idx} title={title} service={services[idx]}></Box>
                </>
              );
            })}
          </WhatWeDoContainer>

          <TableTitle background='#B5D5C5' fontSize='22px' color='#485643'>
            Payment Options
          </TableTitle>

          <PaymentContainer>
            {payOptions.map((option, idx) => {
              return (
                <PaymentOption key={idx}>
                  <BulletIcon src='/check.png' />
                  <p>{option}</p>
                </PaymentOption>
              );
            })}
          </PaymentContainer>
        </TableContainer>
      </TableParent>
    </>
  );
};

export default ServiceTable;

const TableParent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  border-radius: 5px;
  width: 95%;
  margin: auto;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
`;

const PaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 20px;
  font-weight: 100;
  gap: 3rem;

  @media (min-width: 320px) and (max-width: 375px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding-left: 3rem;
  }


  @media (min-width: 375px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding-left: 3rem;
  }
`;

const PaymentOption = styled.div`
  display: flex;
  flex-direction: row;
  color: black;
  align-items: center;
  color: black;
  font-weight: 600;
  gap: 0.5rem;

  p {
    font-size: 22px;
  }




`;

const BulletIcon = styled.img`
  height: 30px;
  width: 30px;
`;
const WhatWeDoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  gap: 6rem;

  .area {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  @media (min-width: 320px) and (max-width: 375px) {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    text-align: center;
  }

  @media (min-width: 375px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    padding: 4rem;
  }
`;

const TableTitle = styled.p`
  font-size: ${(props) => props.fontSize};
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  text-align: center;
  margin: auto;
  padding: 1rem;
`;
