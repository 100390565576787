import React from 'react';
import styled from 'styled-components';

const DesignedByContainer = () => {
  return (
    <TextContainer>
      <p>&copy; 2024 Designed by Brandy Mak</p>
    </TextContainer>
  );
};

export default DesignedByContainer;



const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: #F5F5DC;
  text-align: center;
  font-size: 18px;
  background-color: #B08BBB;

  & p {
    padding: 10px;
  }

  @media (min-width: 320px) and (max-width: 375px) {
    display: none;
  }

  @media (min-width: 375px) and (max-width: 768px) {
   background-color: #B08BBB;
  }
`;
