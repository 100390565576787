import React from 'react';
import Navbar from './NavBar';
import AboutMe from './AboutMe';
import Services from './Services';
import ContactMe from './ContactMe';
import Footer from './Footer';
import Resources from './Resources';
import Location from './Location';

const LandingPage = () => {
  return (
    <>
      <Navbar />
      <Location />
      <Services />
      <AboutMe />
      <Resources />
      <ContactMe />
      <Footer />
    </>
  );
};

export default LandingPage;
