import React from 'react';
import styled from 'styled-components';

const Box = ({ title, service }) => {
  return (
    <BoxContainer>
      <h2>{title}</h2>
      <div>{service}</div>
    </BoxContainer>
  );
};

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  div {
    font-size: 20px;
    text-align: center;
  }


  @media (min-width: 375px) and (max-width: 768px) {
    text-align: center;
  }
`;

export default Box;
