import React from 'react';
import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StyledDivider } from '../shared/sharedComponents';
const Resources = () => {
  return (
    <ResourcesContainer name='resources'>
      <StyledDivider>Resources</StyledDivider>

      <ResourcesList>
        <ResourceBox>
          <h3 className='resource-header'>
            What is an SLP's scope of practice?
          </h3>
          <Accordion disableGutters style={{ fontSize: '20px' }}>
            <AccordionSummary
              style={{ fontSize: '20px', fontWeight: '350' }}
              expandIcon={<ExpandMoreIcon />}
            >
              Learn More
            </AccordionSummary>
            <StyledAccordionDetails style={{ fontSize: '20px', fontWeight: '350' }}>
              <p>
                Speech-language pathologists (SLPs) play a critical and direct
                role in the development of literacy in children and adolescents
                and in the diagnosis, assessment, and treatment of written
                language disorders, including dyslexia, given that
              </p>

              <ul>
                <li>
                  SLPs have unique knowledge about the subsystems of language as
                  they relate to spoken and written language and knowledge of
                  the metalinguistic skills required for reading and writing
                  (e.g., phonological, semantic, orthographic, and morphological
                  awareness);
                </li>

                <li>
                  {' '}
                  spoken language provides the foundation for the development of
                  reading and writing abilities;
                </li>

                <li>
                  {' '}
                  spoken and written language are interconnected at every level;
                </li>

                <li>
                  {' '}
                  children with spoken language problems and with language
                  impairment often have difficulty learning to read and write;
                  and
                </li>

                <li>
                  {' '}
                  instruction in one modality (spoken or written) can influence
                  growth in the other modality.
                </li>
              </ul>

              <p>
                The following roles and activities for SLPs include clinical
                services (assessment, diagnosis, planning, and treatment);
                prevention and advocacy; and education, administration, and
                research (ASHA, 2016): (including but not limited to)
              </p>

              <ul>
                <li>
                  Making decisions about the management of written language
                  disorders
                </li>

                <li>
                  {' '}
                  Counseling persons with written language disorders and their
                  families regarding communication-related issues and providing
                  education aimed at preventing further complications relating
                  to written language disorders
                </li>

                <li>
                  {' '}
                  Consulting and collaborating with other professionals, family
                  members, caregivers, and others to facilitate program
                  development and to provide supervision, evaluation, and/or
                  expert testimony, as appropriate
                </li>
              </ul>

              <div style={{fontSize: '14px'}}>
                Source:
                https://www.asha.org/practice-portal/clinical-topics/written-language-disorders/#collapse_5
              </div>
            </StyledAccordionDetails>
          </Accordion>

          <h3 className='resource-header'>
            What can I do with a dyslexia diagnosis? Do I need a diagnosis?
          </h3>
          <Accordion disableGutters style={{ fontSize: '20px' }}>
            <AccordionSummary
              style={{ fontSize: '20px', fontWeight: '350' }}
              expandIcon={<ExpandMoreIcon />}
            >
              Learn More
            </AccordionSummary>
            <StyledAccordionDetails>
              <p>
                A diagnosis of dyslexia is not needed in order to provide
                appropriate reading instruction. A skilled teacher can teach an
                individual to read based on the way they respond to instruction.
                However:
              </p>

              <ul>
                <li>
                  Sometimes a diagnosis is helpful if you are advocating for
                  your child to receive more appropriate services through an IEP
                  or 504 Plan.
                </li>

                <li>
                  {' '}
                  Sometimes a diagnosis is helpful if a student wants
                  accommodations in college under ADA laws.
                </li>

                <li>
                  {' '}
                  Sometimes, parents and individuals just want to really
                  understand why there is a struggle with learning to read and
                  spell.
                </li>

                <li>
                  {' '}
                  If you want to know if you or your child has dyslexia, you
                  should be able to access appropriate testing.
                </li>
              </ul>

              <StyledRopeImg src='/rope.png' alt='rope' />
            </StyledAccordionDetails>
          </Accordion>

          <h3 className='resource-header'>
            What types of payment do you accept?
          </h3>
          <Accordion disableGutters style={{ fontSize: '20px' }}>
            <AccordionSummary
              style={{ fontSize: '20px', fontWeight: '350' }}
              expandIcon={<ExpandMoreIcon />}
            >
              Learn More
            </AccordionSummary>
            <StyledAccordionDetails>
              <ul>
                <li>Credit Card (private pay)</li>

                <li> HSA/FSA Card</li>

                <li> Payment through school system purchase orders</li>
              </ul>
            </StyledAccordionDetails>
          </Accordion>
        </ResourceBox>
      </ResourcesList>
    </ResourcesContainer>
  );
};

export default Resources;

const ResourcesContainer = styled.div`
  padding: 2rem;
  h2 {
    font-size: 35px;
    font-weight: 300;
    text-align: center;
    color: #023020;
  }
`;

const ResourcesList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  padding: 2rem;
  font-weight: 100;
  gap: 1rem;

  .resource-header {
    color: #b08bbb;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }

  @media (min-width: 375px) and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const ResourceBox = styled.div`
  margin: auto;
  max-width: 95%;

  .resource-header {
    color: #b08bbb;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  font-size: 20px;
  font-weight: 350;
  text-align: start;

  p {
    @media (min-width: 375px) and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: start;
      line-height: 1.5rem;
      font-size: 18px;
    }
  }

  ul {
    @media (min-width: 375px) and (max-width: 768px) {
      font-size: 18px;
      text-align: start;
    }
  }
`;


const StyledRopeImg = styled.img`
  display: flex;
  height: 600px;
  width: 800px;
  margin: auto;
  margin-top: 3rem;

  @media (min-width: 320px) and (max-width: 375px) {
    height: 180px;
    width: 190px;
    display: flex;
    align-items: center;
  }

  @media (min-width: 375px) and (max-width: 768px) {
    height: 200px;
    width: 240px;
    display: flex;
    align-items: center;
  }
`;
